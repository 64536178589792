/* eslint-disable eqeqeq, react-hooks/exhaustive-deps */

import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import {
    changeFieldsWithPrefixAndCamelCase,
    getApiRoot,
    getContextRoot,
    getLanguageValue,
    navigateToPath,
    googleRecaptcha,
    getPhoneExtensionsList,
    getFileType,
    isInactiveField,
    notifyClientError
} from "../../common/functions";
import Grid from '@material-ui/core/Grid';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import RegisterBottomButtons from "./RegisterBottomButtons";
import GenericFieldComponent from '../GenericFieldComponent';
import CONSTANTS from '../../common/constants';
import { ACTIONS } from '../../redux/actions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import {CSS_COLORS} from "../../common/cssColors";

function RegisterStage4Component(props) {
    const dispatch = useDispatch();
    const {
        actions
    } = props;

    let [ fileUploadsData, setFileUploadsData ] = useState(getRelevantUploadedFilesData());
    let initialStateData = {
        placesToVisit: '',
        flightDate: null,
        flightNumber: '405',
        flightCompany: 'Ethiopian Airlines',
        flightDestinationHotelName: '',
        flightDestinationHotelAddress: '',
        contactName: '',
        contactAddress: '',
        contactAddressLine1: '',
        contactAddressLine2: '',
        contactApartmentNumber: '',
        contactCity: '',
        contactPhoneCountryCode: '+251',
        contactPhoneNumber: '',
        addressWhileInCountryIsLikeHotelAddressFlag: false,
        addressWhileInCountryIsLikeContactAddressFlag: false,

        addressLine2: '',
        apartmentNumber: '',

        emergencyContactName: '',
        emergencyContactFirstName: '',
        emergencyContactLastName: '',
        emergencyContactAddress: '',
        emergencyContactEmail: '',
        emergencyContactPhoneCountryCode: '+972',
        emergencyContactPhoneNumber: '',
        // override with props data
        ...props.stage4Data
    };
    if (!initialStateData.flightCompany) {
        initialStateData.flightCompany = 'select';
    }
    if (!initialStateData.contactPhoneCountryCode) {
        initialStateData.contactPhoneCountryCode = '+251';
    }
    if (!initialStateData.emergencyContactPhoneCountryCode) {
        initialStateData.emergencyContactPhoneCountryCode = '+972';
    }
    const [ fieldsData, setFieldsData ] = useState(initialStateData);
    const [ loaders, setLoaders ] = useState({});
    let [ fieldErrors, setFieldErrors ] = useState({ });

    let VISIT_INFORMATION_FIELDS = [
        {
            key: 'placesToVisit',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: (value) => {
                        if (!/^([a-zA-Z0-9,\s]*)$/g.test(value)) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.placesToVisit ? ' field-error' : ''),
                    error: fieldErrors.placesToVisit,
                    value: fieldsData.placesToVisit,
                    inputProps: {
                        name: 'placesToVisit'
                    },
                    helperText: fieldErrors.placesToVisit || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'placesToVisit'}),
                    label: getLanguageValue('register-individual.stage4-places-to-visit') + ' *'
                }
            }
        }
    ];

    VISIT_INFORMATION_FIELDS = VISIT_INFORMATION_FIELDS.filter(item => !isInactiveField('stage4', item.key));

    let FLIGHT_FIELDS = [
        {
            key: 'flightDate',
            validators: [
                {
                    test: (value) => { value = document.querySelector('#flightDate').value; if (value && !moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'date',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.flightDate ? ' field-error' : ''),
                    variant: 'inline',
                    format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                    error: fieldErrors.flightDate,
                    autoOk: true,
                    value: fieldsData.flightDate !== '' ? fieldsData.flightDate : null,
                    inputProps: {
                        name: 'flightDate',
                        id: 'flightDate'
                    },
                    helperText: fieldErrors.flightDate || '',
                    onChange: DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'flightDate' }),
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'flightDate'}),
                    label: getLanguageValue('register-individual.stage4-flight-date') + ' *'
                }
            }
        }
      /*  {
            key: 'flightNumber',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_NUMERIC_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.flightNumber ? ' field-error' : ''),
                    error: fieldErrors.flightNumber,
                    value: fieldsData.flightNumber,
                    inputProps: {
                        maxLength: 6,
                        name: 'flightNumber'
                    },
                    helperText: fieldErrors.flightNumber || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'flightNumber'}),
                    label: getLanguageValue('register-individual.stage4-flight-number') + ' *'
                }
            }
        },
        {
            key: 'flightCompany',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'flightCompany',
                selectLabel: getLanguageValue('register-individual.stage4-flight-company') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage4-select') },
                    ...getLanguageValue('register-individual.stage4-flight-companies-array').split(',').map((value) => ({ value, isDisabled: false, displayText: getLanguageValue('register-individual.stage4-flight-company-' + value) }))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.flightCompany ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.flightCompany,
                    value: fieldsData.flightCompany,
                    inputProps: {
                        name: 'flightCompany',
                        id: 'flightCompany'
                    },
                    helperText: fieldErrors.flightCompany || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        }*/
    ];

    FLIGHT_FIELDS = FLIGHT_FIELDS.filter(item => !isInactiveField('stage4', item.key));

    let FLIGHT_DESTINATION_FIELDS = [
        {
            key: 'flightDestinationHotelName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ADDRESS_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.flightDestinationHotelName ? ' field-error' : ''),
                    error: fieldErrors.flightDestinationHotelName,
                    value: fieldsData.flightDestinationHotelName,
                    inputProps: {
                        name: 'flightDestinationHotelName'
                    },
                    helperText: fieldErrors.flightDestinationHotelName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'flightDestinationHotelName'}),
                    label: getLanguageValue('register-individual.stage4-hotel-name') + ' *'
                }
            }
        },
        {
            key: 'flightDestinationHotelAddress',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ADDRESS_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.flightDestinationHotelAddress ? ' field-error' : ''),
                    error: fieldErrors.flightDestinationHotelAddress,
                    value: fieldsData.flightDestinationHotelAddress,
                    inputProps: {
                        name: 'flightDestinationHotelAddress'
                    },
                    helperText: fieldErrors.flightDestinationHotelAddress || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'flightDestinationHotelAddress'}),
                    label: getLanguageValue('register-individual.stage4-hotel-address') + ' *'
                }
            }
        },
    ];

    FLIGHT_DESTINATION_FIELDS = FLIGHT_DESTINATION_FIELDS.filter(item => !isInactiveField('stage4', item.key));

    let CONTACT_FIELDS = [
        {
            key: 'contactName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.contactName ? ' field-error' : ''),
                    error: fieldErrors.contactName,
                    value: fieldsData.contactName,
                    inputProps: {
                        name: 'contactName'
                    },
                    helperText: fieldErrors.contactName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'contactName'}),
                    label: getLanguageValue('register-individual.stage4-contact-name') + ' *'
                }
            }
        },
        {
            key: 'contactAddress',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: (value) => {
                        if (!/^([a-zA-Z0-9,\-\s]*)$/g.test(value)) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.contactAddress ? ' field-error' : ''),
                    error: fieldErrors.contactAddress,
                    value: fieldsData.contactAddress,
                    inputProps: {
                        name: 'contactAddress'
                    },
                    helperText: fieldErrors.contactAddress || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'contactAddress'}),
                    label: getLanguageValue('register-individual.stage4-address') + ' *'
                }
            }
        },
        {
            key: 'contactAddressLine1',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ADDRESS_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.contactAddressLine1 ? ' field-error' : ''),
                    error: fieldErrors.contactAddressLine1,
                    value: fieldsData.contactAddressLine1,
                    inputProps: {
                        name: 'contactAddressLine1'
                    },
                    helperText: fieldErrors.contactAddressLine1 || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'contactAddressLine1'}),
                    label: getLanguageValue('register-individual.stage4-address-line1') + ' *'
                }
            }
        },
        {
            key: 'contactAddressLine2',
            validators: [
                {
                    test: DEFAULT_ADDRESS_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.contactAddressLine2 ? ' field-error' : ''),
                    error: fieldErrors.contactAddressLine2,
                    value: fieldsData.contactAddressLine2,
                    inputProps: {
                        name: 'contactAddressLine2'
                    },
                    helperText: fieldErrors.contactAddressLine2 || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'contactAddressLine2'}),
                    label: getLanguageValue('register-individual.stage4-address-line2')
                }
            }
        },
        {
            key: 'contactApartmentNumber',
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.contactApartmentNumber ? ' field-error' : ''),
                    error: fieldErrors.contactApartmentNumber,
                    value: fieldsData.contactApartmentNumber,
                    inputProps: {
                        name: 'contactApartmentNumber'
                    },
                    helperText: fieldErrors.contactApartmentNumber || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'contactApartmentNumber'}),
                    label: getLanguageValue('register-individual.stage4-apartment-number')
                }
            }
        },
        {
            key: 'contactCity',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ADDRESS_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.contactCity ? ' field-error' : ''),
                    error: fieldErrors.contactCity,
                    value: fieldsData.contactCity,
                    inputProps: {
                        name: 'contactCity'
                    },
                    helperText: fieldErrors.contactCity || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'contactCity'}),
                    label: getLanguageValue('register-individual.stage4-city') + ' *'
                }
            }
        },
        {
            key: 'contactPhoneCountryCode',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            customMd: 2,
            props: {
                type: 'select',
                selectId: 'contactPhoneCountryCode',
                selectLabel: getLanguageValue('register-individual.stage4-emergency-contact-phone-country-code') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage4-select') },
                    ...(getPhoneExtensionsList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.contactPhoneCountryCode ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.contactPhoneCountryCode,
                    value: fieldsData.contactPhoneCountryCode,
                    inputProps: {
                        name: 'contactPhoneCountryCode',
                        id: 'contactPhoneCountryCode'
                    },
                    helperText: fieldErrors.contactPhoneCountryCode || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'contactPhoneNumber',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_NUMERIC_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.contactPhoneNumber ? ' field-error' : ''),
                    error: fieldErrors.contactPhoneNumber,
                    value: fieldsData.contactPhoneNumber,
                    inputProps: {
                        name: 'contactPhoneNumber'
                    },
                    helperText: fieldErrors.contactPhoneNumber || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'contactPhoneNumber'}),
                    label: getLanguageValue('register-individual.stage4-emergency-contact-phone-number') + ' *'
                }
            }
        }
    ];

    CONTACT_FIELDS = CONTACT_FIELDS.filter(item => !isInactiveField('stage4', item.key));

    let ADDRESS_WHILE_IN_COUNTRY_FIELDS = [

    ];

    ADDRESS_WHILE_IN_COUNTRY_FIELDS = ADDRESS_WHILE_IN_COUNTRY_FIELDS.filter(item => !isInactiveField('stage4', item.key));

    let EMERGENCY_CONTACT_FIELDS = [
        {
            key: 'emergencyContactName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.emergencyContactName ? ' field-error' : ''),
                    error: fieldErrors.emergencyContactName,
                    value: fieldsData.emergencyContactName,
                    inputProps: {
                        name: 'emergencyContactName'
                    },
                    helperText: fieldErrors.emergencyContactName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'emergencyContactName'}),
                    label: getLanguageValue('register-individual.stage4-contact-name') + ' *'
                }
            }
        },
        {
            key: 'emergencyContactFirstName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.emergencyContactFirstName ? ' field-error' : ''),
                    error: fieldErrors.emergencyContactFirstName,
                    value: fieldsData.emergencyContactFirstName,
                    inputProps: {
                        name: 'emergencyContactFirstName'
                    },
                    helperText: fieldErrors.emergencyContactFirstName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'emergencyContactFirstName'}),
                    label: getLanguageValue('register-individual.stage4-emergency-contact-first-name') + ' *'
                }
            }
        },
        {
            key: 'emergencyContactLastName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.emergencyContactLastName ? ' field-error' : ''),
                    error: fieldErrors.emergencyContactLastName,
                    value: fieldsData.emergencyContactLastName,
                    inputProps: {
                        name: 'emergencyContactLastName'
                    },
                    helperText: fieldErrors.emergencyContactLastName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'emergencyContactLastName'}),
                    label: getLanguageValue('register-individual.stage4-emergency-contact-last-name') + ' *'
                }
            }
        },
        {
            key: 'emergencyContactAddress',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: (value) => {
                        if (!/^([a-zA-Z0-9,\-\s]*)$/g.test(value)) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.emergencyContactAddress ? ' field-error' : ''),
                    error: fieldErrors.emergencyContactAddress,
                    value: fieldsData.emergencyContactAddress,
                    inputProps: {
                        name: 'emergencyContactAddress'
                    },
                    helperText: fieldErrors.emergencyContactAddress || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'emergencyContactAddress'}),
                    label: getLanguageValue('register-individual.stage4-address') + ' *'
                }
            }
        },
        {
            key: 'emergencyContactEmail',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: (value) => { if (!new RegExp(CONSTANTS.EMAIL_REGEX, "i").test(value)) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.emergencyContactEmail ? ' field-error' : ''),
                    error: fieldErrors.emergencyContactEmail,
                    value: fieldsData.emergencyContactEmail,
                    inputProps: {
                        name: 'emergencyContactEmail'
                    },
                    helperText: fieldErrors.emergencyContactEmail || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'emergencyContactEmail'}),
                    label: getLanguageValue('register-individual.stage4-emergency-contact-email') + ' *'
                }
            }
        },
        {
            key: 'emergencyContactPhoneCountryCode',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            customMd: 2,
            props: {
                type: 'select',
                selectId: 'emergencyContactPhoneCountryCode',
                selectLabel: getLanguageValue('register-individual.stage4-emergency-contact-phone-country-code') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage4-select') },
                    ...(getPhoneExtensionsList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.emergencyContactPhoneCountryCode ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.emergencyContactPhoneCountryCode,
                    value: fieldsData.emergencyContactPhoneCountryCode,
                    inputProps: {
                        name: 'emergencyContactPhoneCountryCode',
                        id: 'emergencyContactPhoneCountryCode'
                    },
                    helperText: fieldErrors.emergencyContactPhoneCountryCode || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'emergencyContactPhoneNumber',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_NUMERIC_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.emergencyContactPhoneNumber ? ' field-error' : ''),
                    error: fieldErrors.emergencyContactPhoneNumber,
                    value: fieldsData.emergencyContactPhoneNumber,
                    inputProps: {
                        name: 'emergencyContactPhoneNumber'
                    },
                    helperText: fieldErrors.emergencyContactPhoneNumber || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'emergencyContactPhoneNumber'}),
                    label: getLanguageValue('register-individual.stage4-emergency-contact-phone-number') + ' *'
                }
            }
        }
    ];

    EMERGENCY_CONTACT_FIELDS = EMERGENCY_CONTACT_FIELDS.filter(item => !isInactiveField('stage4', item.key));

    function DEFAULT_ON_CHANGE(e) {
        setFieldsData({...fieldsData, [e.target.name] : e.target.value });
        setFieldErrors({ ...fieldErrors, [e.target.name] : null });
    }

    function DEFAULT_EMPTY_VALUE_VALIDATOR(value) {
        if (!value) {
            return false;
        }
        return true;
    }

    function DEFAULT_ADDRESS_VALIDATOR(value) {
        if (!/^([a-zA-Z0-9,\s]*)$/g.test(value)) {
            if (!/^([\u0590-\u05FF0-9,\s]*)$/g.test(value)) {
                return false;
            }
        }
        return true;
    }

    function DEFAULT_ENG_HEB_STRING_VALUE_VALIDATOR(value) {
        if (!DEFAULT_ENG_STRING_VALUE_VALIDATOR(value)) {
            if (!DEFAULT_HEB_STRING_VALUE_VALIDATOR(value)) {
                return false;
            }
        }
        return true;
    }

    function DEFAULT_ENG_STRING_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (!/^([a-zA-Z\s]*)$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_HEB_STRING_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (!/^([\u0590-\u05FF\s]*)$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_NUMERIC_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (value && !/^[0-9]+$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_DATE_ON_CHANGE(date) {
        if (moment(date, true).isValid()) {
            lodashSet(fieldsData, this.fieldName, moment(date).format('YYYY-MM-DD'));
            setFieldsData({ ...fieldsData });
            setFieldErrors({ ...fieldErrors, [this.fieldName] : null });
        }
        else {
            lodashSet(fieldsData, this.fieldName, null);
            setFieldsData({...fieldsData });
        }
    }

    function DEFAULT_ON_BLUR() {
        let matchedField = FLIGHT_FIELDS.find((item) => item.key == this.key) ||
                            FLIGHT_DESTINATION_FIELDS.find((item) => item.key == this.key) ||
                            CONTACT_FIELDS.find((item) => item.key == this.key) ||
                            ADDRESS_WHILE_IN_COUNTRY_FIELDS.find((item) => item.key == this.key) ||
                            EMERGENCY_CONTACT_FIELDS.find((item) => item.key == this.key);
        if (lodashGet(matchedField, 'validators')) {
            let fieldValue = lodashGet(matchedField, 'props.fieldProps.value');
            for (let validatorObj of lodashGet(matchedField, 'validators')) {
                if (!validatorObj.test(fieldValue)) {
                    notifyClientError({ data: fieldsData, errors: { ...fieldErrors, [this.key] : validatorObj.errorMessage } });
                    setFieldErrors({ ...fieldErrors, [this.key] : validatorObj.errorMessage });
                    return;
                }
            }
        }
    }

    useEffect(() => {
        actions.setActiveStage(4);
        window.routeChangeTimestamp = new Date().getTime();
    }, []);

    return <RegisterStage4Style>
        <div className={"form-container"}>
            <h1>{getLanguageValue('register-individual.stage4-enter-travel-information')}</h1>
            <div className="form-information-container">
                <span className="bold-text important-text">{getLanguageValue('register-individual.stage4-provide-responses-in-language')}</span>
                <br/>
                <span className="bold-text field-warning-color">{getLanguageValue('register-individual.stage4-required-fields-with-asterisk')}</span>
            </div>
            {VISIT_INFORMATION_FIELDS.length > 0 && <h2>{getLanguageValue('register-individual.stage4-visit-information')}</h2>}
            {VISIT_INFORMATION_FIELDS.length > 0 && <Grid item className={"form-fields-container visit-information-fields"} md={12}>
                <Grid container className={"fields-container"}>
                    {VISIT_INFORMATION_FIELDS.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container"} md={4}>
                        <GenericFieldComponent {...fieldItem.props} />
                    </Grid>))}
                </Grid>
            </Grid>}
            {FLIGHT_FIELDS.length > 0 && <h2>
                {getLanguageValue('register-individual.stage4-flight-information')}
            </h2>}
            {FLIGHT_FIELDS.length > 0 && <Grid item className={"form-fields-container flight-fields"} md={12}>
                <Grid container className={"fields-container"}>
                    {FLIGHT_FIELDS.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container"} md={4}>
                        <GenericFieldComponent {...fieldItem.props} />
                    </Grid>))}
                </Grid>
            </Grid>}
            {!isInactiveField('stage4', 'flightTicket') && <Grid item className={"upload-flight-ticket-container"} md={4}>
                <Button className={"file-upload-button" + (lodashGet(fileUploadsData, 'flightTicket.error') ? ' field-error' : '')} onClick={() => { document.querySelector('#file-upload-flight-ticket-field').click(); }}>{getLanguageValue('register-individual.stage4-upload-flight-ticket') + ' *'}</Button>
                <input type="file" id="file-upload-flight-ticket-field" onChange={(e) => { onFileUploadChange(e, 'flightTicket'); }} />
                <br/>
                {lodashGet(fileUploadsData, 'flightTicket.error') ? <div className={"file-upload-error"}>{lodashGet(fileUploadsData, 'flightTicket.error')}</div> : null}
                {lodashGet(fileUploadsData, 'flightTicket.base64') ? <img alt="" className={"uploaded-image"} src={lodashGet(fileUploadsData, 'flightTicket.base64')} /> : null}
            </Grid>}
            {FLIGHT_DESTINATION_FIELDS.length > 0 && <h2>
                {getLanguageValue('register-individual.stage4-flight-destination-information')}
            </h2>}
            {FLIGHT_DESTINATION_FIELDS.length > 0 && <Grid item className={"form-fields-container flight-destination-fields"} md={12}>
                <Grid container className={"fields-container"}>
                    {FLIGHT_DESTINATION_FIELDS.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container"} md={4}>
                        <GenericFieldComponent {...fieldItem.props} />
                    </Grid>))}
                </Grid>
            </Grid>}
            {!isInactiveField('stage4', 'hotelPaymentConfirmation') && <Grid item className={"upload-hotel-payment-confirmation-container"} md={4}>
                <Button className={"file-upload-button" + (lodashGet(fileUploadsData, 'hotelPaymentConfirmation.error') ? ' field-error' : '')} onClick={() => { document.querySelector('#file-upload-hotel-payment-field').click(); }}>{getLanguageValue('register-individual.stage4-upload-hotel-payment-confirmation') + ' *'}</Button>
                <input type="file" id="file-upload-hotel-payment-field" onChange={(e) => { onFileUploadChange(e, 'hotelPaymentConfirmation'); }} />
                <br/>
                {lodashGet(fileUploadsData, 'hotelPaymentConfirmation.error') ? <div className={"file-upload-error"}>{lodashGet(fileUploadsData, 'hotelPaymentConfirmation.error')}</div> : null}
                {lodashGet(fileUploadsData, 'hotelPaymentConfirmation.base64') ? <img alt={''} className={"uploaded-image"} src={lodashGet(fileUploadsData, 'hotelPaymentConfirmation.base64')} /> : null}
            </Grid>}


            {CONTACT_FIELDS.length > 0 && <h2>
                {getLanguageValue('register-individual.stage4-contact-information')}
            </h2>}
            {CONTACT_FIELDS.length > 0 && <Grid item className={"form-fields-container contact-fields"} md={12}>
                <Grid container className={"fields-container"}>
                    {CONTACT_FIELDS.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container"} md={fieldItem.customMd || 4}>
                        <GenericFieldComponent {...fieldItem.props} />
                    </Grid>))}
                </Grid>
            </Grid>}
            {EMERGENCY_CONTACT_FIELDS.length > 0 && <h2>
                {getLanguageValue('register-individual.stage4-contact-in-case-of-emergency')}
            </h2>}
            {EMERGENCY_CONTACT_FIELDS.length > 0 && <Grid item className={"form-fields-container contact-in-case-of-emergency-fields"} md={12}>
                <Grid container className={"fields-container"}>
                    {EMERGENCY_CONTACT_FIELDS.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container"} md={fieldItem.customMd || 4}>
                        <GenericFieldComponent {...fieldItem.props} />
                    </Grid>))}
                </Grid>
            </Grid>}
            <RegisterBottomButtons
                actions={{
                    loaders,
                    isNextDisabled,
                    next,
                    back
                }}
            />
            {fieldErrors.submit ? <div className={"submit-error"}>{getLanguageValue('retrieve-individual-information.retrieve-result-network-error')}</div> : null}
        </div>
    </RegisterStage4Style>;

    function toggleAddressWhileInCountryIsLikeContactAddressFlag(e) {
        if (fieldsData.addressWhileInCountryIsLikeHotelAddressFlag) {
            fieldsData.addressWhileInCountryIsLikeHotelAddressFlag = false;
        }
        fieldsData.addressWhileInCountryIsLikeContactAddressFlag = e.target.checked;
        if (!e.target.checked) {
            // clear fields
            for (let fieldItem of ADDRESS_WHILE_IN_COUNTRY_FIELDS) {
                fieldsData[fieldItem.key] = '';
            }
        }
        setFieldsData({ ...fieldsData });
        // clear errors
        let keysAsHash = {};
        for (let item of ADDRESS_WHILE_IN_COUNTRY_FIELDS) {
            keysAsHash[item.key] = false;
        }
        setFieldErrors({ ...fieldErrors, ...keysAsHash });
    }

    function toggleAddressWhileInCountryIsLikeHotelAddressFlag(e) {
        if (fieldsData.addressWhileInCountryIsLikeContactAddressFlag) {
            fieldsData.addressWhileInCountryIsLikeContactAddressFlag = false;
        }
        fieldsData.addressWhileInCountryIsLikeHotelAddressFlag = e.target.checked;
        if (!e.target.checked) {
            // clear fields
            for (let fieldItem of ADDRESS_WHILE_IN_COUNTRY_FIELDS) {
                fieldsData[fieldItem.key] = '';
            }
        }
        setFieldsData({ ...fieldsData });
        // clear errors
        let keysAsHash = {};
        for (let item of ADDRESS_WHILE_IN_COUNTRY_FIELDS) {
            keysAsHash[item.key] = false;
        }
        setFieldErrors({ ...fieldErrors, ...keysAsHash });
    }

    function onFileUploadChange(e, fieldName) {
        const file = e.target.files[0];
        const localFilename = file.name;
        const reader = new FileReader();
        // reset to allow selecting same filename
        e.target.value = '';

        reader.addEventListener("load", async function () {
            // validate
            let fileTypeResult = await getFileType(reader.result);
            let fileTypeResultExtension = lodashGet(fileTypeResult, 'ext');
            if (['jpg', 'jpeg', 'png', 'bmp', 'heic'].indexOf(fileTypeResultExtension) == -1) {
                setFileUploadsData({ ...fileUploadsData, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-type-error'), base64: null, filename: '' } });
                return;
            }
            if (reader.result.length < CONSTANTS.FILESIZE_MIN_LIMIT) {
                setFileUploadsData({ ...fileUploadsData, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-too-small-error'), base64: null, filename: '' } });
                return;
            }
            if (reader.result.length > CONSTANTS.FILESIZE_MAX_LIMIT) {
                setFileUploadsData({ ...fileUploadsData, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-size-error'), base64: null, filename: '' } });
                return;
            }
            setFileUploadsData({ ...fileUploadsData, [fieldName]: { error: false, base64: reader.result, filename: localFilename } });
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    function isNextDisabled() {
        return false;
    }

    async function next() {
        // goto next stage
        let validateResult = validate({ setErrors: true });
        if (validateResult.length) {
            notifyClientError({ data: fieldsData, errors: fieldErrors });
            setTimeout(function() {
                if (document.querySelectorAll('.field-error')[0]) {
                    document.querySelectorAll('.field-error')[0].scrollIntoView();
                }
            }, 50);
            return;
        }
        if (loaders.next) {
            return;
        }
        if (actions && actions.nextStage) {
            try {
                setFieldErrors({ ...fieldErrors, submit: false });
                setLoaders({ ...loaders, next: true });
                let duplicateData = {...fieldsData};
                if (duplicateData.addressWhileInCountryIsLikeContactAddressFlag || duplicateData.addressWhileInCountryIsLikeHotelAddressFlag) {
                    // reset all address fields
                    duplicateData = {
                        ...duplicateData,

                        addressLine2: '',
                        apartmentNumber: '',
                        city: '',
                    };
                }
                duplicateData.emergencyContactPhoneNumber = String(parseInt(duplicateData.emergencyContactPhoneNumber));
                // override field to be integer
                duplicateData.addressWhileInCountryIsLikeContactAddressFlag = (duplicateData.addressWhileInCountryIsLikeContactAddressFlag ? 1 : 0);
                duplicateData.addressWhileInCountryIsLikeHotelAddressFlag = (duplicateData.addressWhileInCountryIsLikeHotelAddressFlag ? 1 : 0);
                let url = getApiRoot() + 'update-process/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
                let recaptchaToken = await googleRecaptcha();
                let options = {
                    ...CONSTANTS.POST_DEFAULT_OPTIONS,
                    body: JSON.stringify({
                        recaptchaToken,
                        ...changeFieldsWithPrefixAndCamelCase(duplicateData, 'stage4'),
                        stage: "4",
                        key: props.individualKey
                    })
                };
                let updateProcessResults = await (await fetch(url, options)).json();
                if (!lodashGet(updateProcessResults, 'data.success')) {
                    setLoaders({ ...loaders, next: false });
                    setFieldErrors({ ...fieldErrors, submit: true });
                    return;
                }
                // try to upload the files
                let newUploadedFilesArray = JSON.parse(JSON.stringify(props.uploadedFiles));
                if ((lodashGet(fileUploadsData, 'flightTicket.base64') && !lodashGet(fileUploadsData, 'flightTicket.previewOnly')) || (lodashGet(fileUploadsData, 'hotelPaymentConfirmation.base64') && !lodashGet(fileUploadsData, 'hotelPaymentConfirmation.previewOnly'))) {
                    url = getApiRoot() + 'upload-files/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
                    recaptchaToken = await googleRecaptcha();
                    let filesArray = [];
                    if (lodashGet(fileUploadsData, 'flightTicket.base64') && !lodashGet(fileUploadsData, 'flightTicket.previewOnly')) {
                        filesArray.push({
                            fileType: 'flightTicket',
                            filename: lodashGet(fileUploadsData, 'flightTicket.filename'),
                            fileContent: lodashGet(fileUploadsData, 'flightTicket.base64')
                        });
                        newUploadedFilesArray.push({
                            fileType: 'flightTicket',
                            filename: lodashGet(fileUploadsData, 'flightTicket.filename'),
                            fileContent: lodashGet(fileUploadsData, 'flightTicket.base64')
                        });
                    }
                    if (lodashGet(fileUploadsData, 'hotelPaymentConfirmation.base64') && !lodashGet(fileUploadsData, 'hotelPaymentConfirmation.previewOnly')) {
                        filesArray.push({
                            fileType: 'hotelPaymentConfirmation',
                            filename: lodashGet(fileUploadsData, 'hotelPaymentConfirmation.filename'),
                            fileContent: lodashGet(fileUploadsData, 'hotelPaymentConfirmation.base64')
                        });
                        newUploadedFilesArray.push({
                            fileType: 'hotelPaymentConfirmation',
                            filename: lodashGet(fileUploadsData, 'hotelPaymentConfirmation.filename'),
                            fileContent: lodashGet(fileUploadsData, 'hotelPaymentConfirmation.base64')
                        });
                    }
                    options = {
                        ...CONSTANTS.POST_DEFAULT_OPTIONS,
                        body: JSON.stringify({
                            recaptchaToken,
                            key: props.individualKey,
                            reset: true,
                            filesArray
                        })
                    };
                    let uploadFilesResults = await (await fetch(url, options)).json();
                    if (!lodashGet(uploadFilesResults, 'data.success')) {
                        setLoaders({ ...loaders, next: false });
                        setFieldErrors({ ...fieldErrors, submit: true });
                        return;
                    }
                }

                duplicateData = {...fieldsData};
                duplicateData.emergencyContactPhoneNumber = String(parseInt(duplicateData.emergencyContactPhoneNumber));
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [
                        {
                            path: 'registerIndividualStagesData.stage4Data',
                            value: JSON.parse(JSON.stringify(duplicateData))
                        },
                        {
                            path: 'registerIndividualStagesData.stage4UploadedFlightTicket',
                            value: true
                        },
                        {
                            path: 'registerIndividualStagesData.stage4UploadedHotelPaymentConfirmation',
                            value: true
                        },
                        {
                            path: 'registerIndividualStagesData.uploadedFiles',
                            value: newUploadedFilesArray
                        }
                    ]
                });
                actions.nextStage();
                // don't need to do anything else
                return;
            }
            catch (err) {
                // nothing to do
                setFieldErrors({ ...fieldErrors, submit: true });
            }
            setLoaders({ ...loaders, next: false });
        }
    }

    function back() {
        setTimeout(() => {
            navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage3');
        }, 10);
    }

    function validate(config) {
        let errors = [];
        if (lodashGet(config, 'setErrors')) {
            fieldErrors = {};
        }
        let fullFieldsList = [].concat(VISIT_INFORMATION_FIELDS).concat(FLIGHT_FIELDS).concat(FLIGHT_DESTINATION_FIELDS).concat(CONTACT_FIELDS).concat(EMERGENCY_CONTACT_FIELDS);
        if (!fieldsData.addressWhileInCountryIsLikeContactAddressFlag && !fieldsData.addressWhileInCountryIsLikeHotelAddressFlag) {
            fullFieldsList = fullFieldsList.concat(ADDRESS_WHILE_IN_COUNTRY_FIELDS);
        }
        for (let fieldItem of fullFieldsList) {
            let fieldValue = fieldsData[fieldItem.key];
            let fieldValidators = fieldItem.validators;
            if (fieldValidators) {
                for (let fieldValidatorItem of fieldValidators) {
                    if (!fieldValidatorItem.test(fieldValue)) {
                        errors.push(fieldItem.key);
                        if (lodashGet(config, 'setErrors')) {
                            fieldErrors[fieldItem.key] = fieldValidatorItem.errorMessage;
                        }
                        break;
                    }
                }
            }
        }
        // check upload fields
        let localFileUploadsData = { ...fileUploadsData };
        let fileUploadsDataChanged = false;
        if (!isInactiveField('stage4', 'flightTicket') && !props.stage4UploadedFlightTicket) {
            if (!lodashGet(fileUploadsData, 'flightTicket.base64')) {
                localFileUploadsData.flightTicket = { ...localFileUploadsData.flightTicket, error: getLanguageValue('register-field-errors-must-upload') };
                fileUploadsDataChanged = true;
                errors.push('flightTicketFile');
            }
        }
        if (!isInactiveField('stage4', 'hotelPaymentConfirmation') && !props.stage4UploadedHotelPaymentConfirmation) {
            if (!lodashGet(fileUploadsData, 'hotelPaymentConfirmation.base64')) {
                errors.push('hotelPaymentConfirmationFile');
                localFileUploadsData.hotelPaymentConfirmation = { ...localFileUploadsData.hotelPaymentConfirmation, error: getLanguageValue('register-field-errors-must-upload') };
                fileUploadsDataChanged = true;
            }
        }

        if (lodashGet(config, 'setErrors')) {
            setFieldErrors(fieldErrors);
            if (fileUploadsDataChanged) {
                setFileUploadsData(localFileUploadsData);
            }
        }
        return errors;
    }

    function getRelevantUploadedFilesData() {
        let output = {};
        if (props.uploadedFiles) {
            for (let loop = 0 ; loop < props.uploadedFiles.length ; ++loop) {
                let item = props.uploadedFiles[loop];
                if (item.fileType == 'hotelPaymentConfirmation') {
                    output['hotelPaymentConfirmation'] = {
                        error: false,
                        filename: item.filename,
                        base64: item.fileContent,
                        previewOnly: true
                    };
                }
                if (item.fileType == 'flightTicket') {
                    output['flightTicket'] = {
                        error: false,
                        filename: item.filename,
                        base64: item.fileContent,
                        previewOnly: true
                    };
                }
            }
        }
        return output;
    }
}

const RegisterStage4Style = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  .form-container {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 25px;
  }
  .single-field-container {
    width: 100%;
    margin-bottom: 25px;
  }
  .field-component {
    width: calc(100% - 20px);
    html.rtl & {
      margin-left: 20px;
    }
    html.ltr & {
      margin-right: 20px;
    }
  }
  .upload-flight-ticket-container {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100%;
    }
  }
  .file-upload-button {
    width: calc(100% - 20px);
    height: 50px;
    border: 1px solid black;
    &.field-error {
      border-color: ${CSS_COLORS.FIELD_WARNING};
      color: ${CSS_COLORS.FIELD_WARNING};
    }
  }
  #file-upload-hotel-payment-field, #file-upload-flight-ticket-field {
    display: none;
  }
  .file-upload-error {
    color: ${CSS_COLORS.FIELD_WARNING};
    margin-top: 15px;
  }
  .uploaded-image {
    max-width: 100%;
    border: 1px dashed black;
    margin-top: 15px;
  }
  .address-same-as-contact-form-component, .address-same-as-hotel-form-component {
    html.rtl & {
      margin-right: -10px;
    }
  }
  .submit-error {
    margin-top: 10px;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
`;

const RegisterStage4 = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        registerIndividualStagesData: state.registerIndividualStagesData,
        stage4UploadedFlightTicket: lodashGet(state, 'registerIndividualStagesData.stage4UploadedFlightTicket') || false,
        stage4UploadedHotelPaymentConfirmation: lodashGet(state, 'registerIndividualStagesData.stage4UploadedHotelPaymentConfirmation') || false,
        individualKey: lodashGet(state, 'registerIndividualStagesData.key'),
        stage4Data: lodashGet(state, 'registerIndividualStagesData.stage4Data'),
        uploadedFiles: lodashGet(state, 'registerIndividualStagesData.uploadedFiles'),
    }),
    {})(RegisterStage4Component);

export default RegisterStage4;
