import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {getContextRoot, navigateToPath, getLanguageValue} from "../common/functions";
import CONSTANTS from '../common/constants';
import { useLocation } from 'react-router-dom';

function HeaderWithLogoBackground(props) {

    const location = useLocation();
    const dispatch = useDispatch();

    return <HeaderWithLogoBackgroundStyle className={props.className || ''}>
        <div className={"background-component"}/>
        {CONSTANTS.YOUTUBE_ON_HEADER_ENABLED && <div className={"youtube-container"}><iframe id="ytplayer" type="text/html"  src="https://www.youtube.com/embed/s5Pfpu0uoH8?autoplay=1" frameborder="0" allowfullscreen></iframe></div>}
        <div className={"logo-component"}>
            <a href="#" onClick={(e) => {
                e.preventDefault();
                window.location.href = (getContextRoot() || '/');
            }}><img src={getContextRoot() + 'images/logo.png'} alt="" /></a>
        </div>
    </HeaderWithLogoBackgroundStyle>;
}

const HeaderWithLogoBackgroundStyle = styled.div`
  position: relative;
  height: 280px;

  > div {
    display: inline-block;
  }
  .youtube-container {
    position: absolute;
    top: 55px;
    left: 182px;
    height: 200px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      left: calc(50% - 144px);
      z-index: 1;
    }
    > iframe {
      border-radius: 20px;
      width: 100%;
      height: 100%;
    }
  }
  .logo-component {
    img {
        //width: 275px;
        background-size: 97%;
        height: 280px;
        position: absolute;
        top: 10px;
        right: 0;
        html.ltr & {
          @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
            right: unset;
            left: 0;
          }
        }
        @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
          height: 150px;
          right: calc(50% - 75px);
        }
        html[data-profile="911pcrfr"] & {
          @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
            right: unset;
            left: 0;
          }
        }
    }
    //background: url('${getContextRoot() + 'images/logo.png'}') no-repeat 100% 0;
  }
  .background-component {
    background: url('${getContextRoot() + 'images/country_bg.jpg'}') no-repeat 100% 0;
    background-size: cover;
    width: 100%;
    height: 280px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      opacity: 0.5;
    }
  }
`;

export default HeaderWithLogoBackground;
