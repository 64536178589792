// PROD

const initialState = {
    languageKey: 'en',
    mainPageAlertsHidden: false,
    routerRedirect: '',
    noStageReset: true,
    retrieveIndividualApplication: {
        key: '',
        passportNumber: '',
        birthDate: '',
    },
    retrieveGroupApplication: {
        key: '',
        lastName: '',
        firstName: '',
        email: '',
        birthDate: ''
    },
    registerIndividualStagesData: {
        key: '',
        stage2Data: { },
        stage3Data: { },
        stage4Data: { },
        stage5Data: { },
        stage6Data: { },
        stage7Data: { },
        uploadedFiles: []
    },
    rejectedApplicationData: {
        groupKey: null,
        formKey: null,
        fields: {},
        files: {}
    },
    registerGroupStagesData: {
        createDate: '',
        status: '',
        key: '',
        stage1Data: {
            firstName: '',
            lastName: '',
            organization: '',
            birthDate: '',
            email: '',
            emailConfirm: ''
        },
        stage2Data: {
            linkedIndividualApplications: [
            ]
        }
    },
    globalControls: {
        // customize
        refreshGroupData: false,
        isIndividualLinkedToGroup: false,
        isRetrieveLinkedToGroup: false,
        openWarningForIndividual: false
    },
    warningForIndividualConfig: {
        title: '',
        content: ''
    },
    apiData: {
    }
};

export default initialState;
